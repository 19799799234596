<template>
  <div>
    <v-container v-if="user">
      <v-row no-gutters class="mb-5">
        <v-col cols="12" class="d-flex flex-column justify-start">
          <h4 class="font-weight-bold default--text">
            {{ $t("dashboard.title", [user.firstName]) }}
          </h4>
          <span class="text-caption" v-html="$t('dashboard.subtitle')"> </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
          v-for="(item, idx) in dashboardItems"
          :key="idx"
          class="pa-3"
        >
          <DashboardCard
            :title="$t(`dashboard.${item.title}`)"
            :icon="item.icon"
            :routePath="item.routePath"
            :navigation="item.navigation"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import DashboardCard from "@/components/profile/DashboardCard.vue";

import RegistrationService from "~/service/userService";

export default {
  name: "Profile",
  data() {
    return {
      dashboardItems: [
        {
          title: "profile",
          icon: "$profile",
          routePath: "/profile/profile-update",
          navigation: true
        },
        {
          title: "orders",
          icon: "$orders",
          routePath: "/profile/orders",
          navigation: true
        },
        {
          title: "lists",
          icon: "$heart",
          routePath: "/profile/lists",
          navigation: true
        },
        // {
        //   title: "favorite",
        //   icon: "$like",
        //   routePath: "/profile/favorite"
        // },
        {
          title: "addresses",
          icon: "$location",
          routePath: "/profile/addresses",
          navigation: true
        },
        {
          title: "paymentMethod",
          icon: "$creditcard",
          routePath: "/profile/payment-method",
          navigation: true
        },
        {
          title: "logout",
          icon: "$logout",
          routePath: "doLogout",
          navigation: false
        }
      ],
      user: null
    };
  },
  created() {
    this.fetchUserData();
  },
  components: {
    DashboardCard
  },
  methods: {
    fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
      });
    }
  }
};
</script>
